<template>
    <!--Left Sidenav-->
    <div class="left-sidenav">
        <!-- LOGO -->
        <div class="brand text-start ms-2">
            <a href="javascript:void(0)" class="logo" v-on:click="GoTo('/StartScreen')">
                <span>
                    <img src="logo.png" alt="logo-small" class="logo-sm"
                        style="width:100px;height:auto; max-height:45px;">
                </span>
            </a>
        </div>
        <!--end logo-->
        <div class="menu-content h-100" data-simplebar v-if="isHrPortal" hidden>
            <ul class="metismenu left-sidenav-menu" id="metismenu">
                <li>
                    <a href="javascript:void(0);">
                        <i class="ti-control-record"></i>Leave Management<span class="menu-arrow left-has-menu">
                            <i class="mdi mdi-chevron-right"></i>
                        </span>
                    </a>

                    <ul class="nav-second-level" aria-expanded="false">
                        <li>
                            <a v-on:click="GoTo('/Leave', 'HR And PayRoll_token', 'true')" href="javascript:void(0);">
                                Leave</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <div class="menu-content h-100" data-simplebar v-else>
            <ul class="metismenu left-sidenav-menu">



                <li v-if="role == 'Noble Admin'">
                    <a href="javascript:void(0);" v-on:click="GoTo('/clientManagement')">
                        <i data-feather="trending-up" class="align-self-center menu-icon"></i><span>Client Management</span>
                    </a>

                </li>


                <li v-if="role == 'HrUser'">
                    <div class="col-lg-4  ">
                        <img :src="photo" alt="" height="200" width="200"  class="rounded-circle">
                    </div>
                </li>
                <!-- <li v-if="role == 'HrUser'">
                    <a > {{ $t('Hr Portal') }}</a>
                    <a > {{ $t('Pay-Roll') }}</a>
                </li> -->





                <!-- Human Resource Management Menu Bar Code  -->
                <li v-if="role == 'Admin'">

                    <a href="javascript:void(0);">
                        <i class="ti-user text-secondary font-16 align-middle"></i><span>
                            {{ $t('Dashboard.HumanResources') }}
                        </span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span>
                    </a>
                    <!-- <a href="javascript:void(0);"
                        v-on:click="GoTo('/employeeRegistration', 'HR And PayRoll_token', 'true')">
                        <i data-feather="user" class="align-self-center menu-icon"></i><span>{{
                            $t('Dashboard.EmployeeProfile') }}</span>
                    </a> -->
                    
                    <ul class="nav-second-level" aria-expanded="false">

                        <!-- <a href="javascript:void(0);"
                            v-on:click="GoTo('/employeeDraft', 'HR And PayRoll_token', 'true')">
                            <i data-feather="user" class="align-self-center menu-icon"></i><span>{{
                                $t('Dashboard.EmployeeDraftProfile') }}</span>
                       </a> -->

                        <li>
                            <a v-on:click="GoTo('/employeeDraft', 'HR And PayRoll_token', 'true')"
                                href="javascript:void(0);"> {{ $t('Dashboard.DraftEmployeeProfiles') }}</a>
                        </li>
                        <li>
                            <a v-on:click="GoTo('/companyEmployee', 'HR And PayRoll_token', 'true')"
                                href="javascript:void(0);"> {{ $t('Employee Contracts') }}</a>
                        </li>
                         
                        <li>
                            <!-- <a v-on:click="GoTo('/CommingSoon')" href="javascript:void(0);"> {{ $t('Dashboard.Setup') }}</a> -->
                            <a href="javascript:void(0);">
                                <i class="ti-control-record"></i>Setup<span class="menu-arrow left-has-menu">
                                    <i class="mdi mdi-chevron-right"></i>
                                </span>
                            </a>

                            <ul class="nav-second-level" aria-expanded="false">
                                <li>
                                    <a v-on:click="GoTo('/Folder', 'HR And PayRoll_token', 'true')"
                                        href="javascript:void(0);"> {{ $t('Document Types') }}</a>
                                </li>
                                <li>
                                    <a v-on:click="GoTo('/ContractTemplate', 'HR And PayRoll_token', 'true')"
                                        href="javascript:void(0);"> {{ $t('Contract Template') }}</a>
                                </li>
                                <li>
                                    <a v-on:click="GoTo('/employeeLocation', 'HR And PayRoll_token', 'true')"
                                        href="javascript:void(0);"> {{ $t('Employee Location') }}</a>
                                </li>

                            </ul>
                        </li>


                        
                    </ul>
                    </li>




                <!-- <li v-if="role == 'Admin'">
                    <a href="javascript:void(0);">
                        <i class="ti-control-record"></i>Request Approver<span class="menu-arrow left-has-menu">
                            <i class="mdi mdi-chevron-right"></i>
                        </span>
                    </a>
                    <ul class="nav-second-level" aria-expanded="false">
                        <li>
                            <a v-on:click="GoTo('/RequestApprover')"
                                        href="javascript:void(0);"> {{ $t('Request Approver') }}</a>
                        </li>
                    </ul>
                </li> -->
                <li v-if="role == 'Admin'">
                    <a href="javascript:void(0);">
                        <i class="ti-control-record"></i>Leave Management<span class="menu-arrow left-has-menu">
                            <i class="mdi mdi-chevron-right"></i>
                        </span>
                    </a>
                    <ul class="nav-second-level" aria-expanded="false">
                        <li>
                            <a v-on:click="GoTo('/LeaveAssigning')"
                                        href="javascript:void(0);"> {{ $t('Leave Assigning') }}</a>
                        </li>
                        <li>
                            <a v-on:click="GoTo('/EmployeeLeaveRequest')"
                                        href="javascript:void(0);"> {{ $t('Employee Leave Request') }}</a>
                        </li>
                        <li>
                            <a v-on:click="GoTo('/LeaveTypes', 'HR And PayRoll_token', 'true')"
                                href="javascript:void(0);"> {{ $t('Leave Types') }}</a>
                        </li>
                        <li>
                            <a v-on:click="GoTo('/PublicHolidays', 'HR And PayRoll_token', 'true')"
                                href="javascript:void(0);"> {{ $t('Public Holidays') }}</a>
                        </li>
                        <!-- <li>
                            <a v-on:click="GoTo('/Leave', 'HR And PayRoll_token', 'true')" href="javascript:void(0);">
                                Leave</a>
                        </li> -->
                    </ul>
                </li>

                <li v-if="role == 'Admin'">
                    <a href="javascript:void(0);">
                        <i class="ti-control-record"></i>{{ $t('Dashboard.PayrollManagement') }}<span class="menu-arrow left-has-menu">
                            <i class="mdi mdi-chevron-right"></i>
                        </span>
                    </a>

                    <ul class="nav-second-level" aria-expanded="false">
                        <li>
                            <a href="javascript:void(0);">
                                <i class="ti-control-record"></i>Setup<span class="menu-arrow left-has-menu">
                                    <i class="mdi mdi-chevron-right"></i>
                                </span>
                            </a>

                            <ul class="nav-second-level" aria-expanded="false">
                                <li>
                                    <a v-on:click="GoTo('/AllowanceType', 'HR And PayRoll_token', 'true')"
                                        href="javascript:void(0);"> {{ $t('Dashboard.AllowanceType') }}</a>
                                </li>
                                <li>
                                    <a v-on:click="GoTo('/Allowance', 'HR And PayRoll_token', 'true')"
                                        href="javascript:void(0);"> {{ $t('Dashboard.Allowance') }}</a>
                                </li>
                                <li>
                                    <a v-on:click="GoTo('/Contribution', 'HR And PayRoll_token', 'true')"
                                        href="javascript:void(0);"> {{ $t('Dashboard.Contribution') }}</a>
                                </li>
                                <li>
                                    <a v-on:click="GoTo('/Deduction', 'HR And PayRoll_token', 'true')"
                                        href="javascript:void(0);"> {{ $t('Dashboard.Deduction') }}</a>
                                </li>
                                <li>
                                    <a v-on:click="GoTo('/MonthlyDeduction', 'HR And PayRoll_token', 'true')"
                                        href="javascript:void(0);"> {{ 'Monthly Deduction' }}</a>
                                </li>
                                <li>
                                    <a v-on:click="GoTo('/SalaryTemplate', 'HR And PayRoll_token', 'true')"
                                        href="javascript:void(0);"> {{ $t('Dashboard.SalaryTemplate') }}</a>
                                </li>
                                <li>
                                    <a v-on:click="GoTo('/PayrollSchedule', 'HR And PayRoll_token', 'true')"
                                        href="javascript:void(0);"> {{ $t('Dashboard.PayrollSchedule') }}</a>
                                </li>

                                <li>
                                    <a v-on:click="GoTo('/EmployeeSalary', 'HR And PayRoll_token', 'true')"
                                        href="javascript:void(0);"> {{ $t('Dashboard.EmployeeSalary') }}</a>
                                </li>
                                <li>
                                    <a v-on:click="GoTo('/LoanPayment', 'HR And PayRoll_token', 'true')"
                                        href="javascript:void(0);"> {{ $t('Dashboard.LoanPayment') }}</a>
                                </li>
                                <li>
                                    <a v-on:click="GoTo('/SalaryTaxSlab', 'HR And PayRoll_token', 'true')"
                                        href="javascript:void(0);"> {{ $t('Dashboard.SalaryTaxSlab') }}</a>
                                </li>

                            </ul>
                        </li>

                        <li>
                            <a v-on:click="GoTo('/RunPayroll', 'HR And PayRoll_token', 'true')" href="javascript:void(0);">
                                {{ $t('Dashboard.Payroll') }}</a>
                        </li>
                        <li>
                            <a v-on:click="GoTo('/CommingSoon')" href="javascript:void(0);"> {{
                                $t('Dashboard.Payslip') }}</a>
                        </li>
                        <li>
                            <a v-on:click="GoTo('/CommingSoon')" href="javascript:void(0);"> {{
                                $t('Dashboard.Reports') }}</a>
                        </li>

                    </ul>
                </li>
                <li v-if="role == 'Admin'">
                    <a href="javascript:void(0);">
                        <i class="ti-control-record"></i>Shift Management<span class="menu-arrow left-has-menu">
                            <i class="mdi mdi-chevron-right"></i>
                        </span>
                    </a>

                    <ul class="nav-second-level" aria-expanded="false">
                        <li>
                            <a v-on:click="GoTo('/Shifts&WorkingHours')"
                                href="javascript:void(0);"> {{ $t('Shifts & Working Hours') }}</a>
                        </li>
                        <li>
                            <a v-on:click="GoTo('/OverTime')"
                                href="javascript:void(0);"> {{ $t('Over Time') }}</a>
                        </li>
                        <li>
                            <a v-on:click="GoTo('/EmployeeOverTimeRequest')"
                                        href="javascript:void(0);"> {{ $t('Employee OverTime Request') }}</a>
                        </li>

                    </ul>
                    
                </li>
                <li v-if="role == 'Admin'">
                    <a href="javascript:void(0);">
                                <i class="ti-control-record"></i>Approval Management<span class="menu-arrow left-has-menu">
                                    <i class="mdi mdi-chevron-right"></i>
                                </span>
                     </a>
                             <ul class="nav-second-level" aria-expanded="false">
                                <li>
                                    <a v-on:click="GoTo('/Requests&Approvals')"
                                        href="javascript:void(0);"> {{ $t('Requests & Approvals') }}</a>
                                </li>
                                
                            </ul>
                </li>
                <li v-if="role == 'Admin'">
                    <a href="javascript:void(0);">
                        <i class="ti-control-record"></i>{{ $t('Dashboard.AttendanceManagement') }}<span
                            class="menu-arrow left-has-menu">
                            <i class="mdi mdi-chevron-right"></i>
                        </span>
                    </a>

                    <ul class="nav-second-level" aria-expanded="false">
                        <li>
                            <a v-on:click="GoTo('/Attendance', 'HR And PayRoll_token', 'true')"
                                href="javascript:void(0);"> {{ $t('Attendece') }}</a>
                        </li>
                        <li>
                            <a v-on:click="GoTo('/ManualAttendance', 'HR And PayRoll_token', 'true')"
                                href="javascript:void(0);"> {{ $t('Dashboard.ManualAttendece') }}</a>
                        </li>
                        <li>
                            <a v-on:click="GoTo('/AttendanceReportNew', 'HR And PayRoll_token', 'true')"
                                href="javascript:void(0);"> {{ $t('Attendance Report') }}</a>
                        </li>
                        <!-- <li>
                            <a v-on:click="GoTo('/EmployeeTodayAttendence', 'HR And PayRoll_token', 'true')"
                                href="javascript:void(0);"> {{ $t('Dashboard.TodayAttendece') }} </a>
                        </li> -->
                        <!-- <li>
                            <a v-on:click="GoTo('/AttendanceReport', 'HR And PayRoll_token', 'true')"
                                href="javascript:void(0);"> {{ $t('Dashboard.Reports') }}</a>
                        </li>
                        <li>
                            <a v-on:click="GoTo('/AddHolidayOfMonth', 'HR And PayRoll_token', 'true')"
                                href="javascript:void(0);"> {{ $t('Dashboard.Setup') }}</a>
                        </li> -->

                    </ul>
                </li>
                <!-- <li v-if="role == 'Admin'">
                            <a href="javascript:void(0);">
                                <i class="ti-control-record"></i>{{ $t('Dashboard.EmployeePortal') }}<span
                                    class="menu-arrow left-has-menu">
                                    <i class="mdi mdi-chevron-right"></i>
                                </span>
                            </a>

                            <ul class="nav-second-level" aria-expanded="false">
                                <li>
                                    <a v-on:click="GoTo('/CommingSoon')" href="javascript:void(0);"> {{
                                        $t('Dashboard.Portal') }}</a>
                                </li>
                                <li>
                                    <a v-on:click="GoTo('/CommingSoon')" href="javascript:void(0);"> {{
                                        $t('Dashboard.Reports') }}</a>
                                </li>
                                <li>
                                    <a v-on:click="GoTo('/CommingSoon')" href="javascript:void(0);"> {{
                                        $t('Dashboard.Setup') }}</a>
                                </li>
                            </ul>
                </li> -->
                <li v-if="role == 'Admin'">
                    <a href="javascript:void(0);">
                        <i class="ti-control-record"></i>{{ $t('Dashboard.TasksManagement') }}<span
                            class="menu-arrow left-has-menu">
                            <i class="mdi mdi-chevron-right"></i>
                        </span>
                    </a>

                    <ul class="nav-second-level" aria-expanded="false">
                        <li>
                            <a v-on:click="GoTo('/CommingSoon')" href="javascript:void(0);"> {{
                                $t('Dashboard.Tasks') }}</a>
                        </li>
                        <li>
                            <a v-on:click="GoTo('/CommingSoon')" href="javascript:void(0);"> {{
                                $t('Dashboard.Reports') }}</a>
                        </li>
                        <li>
                            <a v-on:click="GoTo('/CommingSoon')" href="javascript:void(0);"> {{
                                $t('Dashboard.Setup') }}</a>
                        </li>
                    </ul>
                </li>
                <li v-if="role == 'Admin'">
                    <a href="javascript:void(0);">
                        <i class="ti-control-record"></i>{{ $t('Dashboard.AssetsManagement-I') }}<span
                            class="menu-arrow left-has-menu">
                            <i class="mdi mdi-chevron-right"></i>
                        </span>
                    </a>

                    <ul class="nav-second-level" aria-expanded="false">
                        <li>
                            <a v-on:click="GoTo('/CommingSoon')" href="javascript:void(0);"> {{
                                $t('Dashboard.Assets') }}</a>
                        </li>
                        <li>
                            <a v-on:click="GoTo('/CommingSoon')" href="javascript:void(0);"> {{
                                $t('Dashboard.Reports') }}</a>
                        </li>
                        <li>
                            <a v-on:click="GoTo('/CommingSoon')" href="javascript:void(0);"> {{
                                $t('Dashboard.Setup') }}</a>
                        </li>
                    </ul>
                </li>
                <li v-if="role == 'Admin'">
                    <a href="javascript:void(0);">
                        <i class="ti-control-record"></i>{{ $t('Dashboard.DocumentManagement') }}<span
                            class="menu-arrow left-has-menu">
                            <i class="mdi mdi-chevron-right"></i>
                        </span>
                    </a>

                    <ul class="nav-second-level" aria-expanded="false">
                        <li>
                            <a v-on:click="GoTo('/CommingSoon')" href="javascript:void(0);"> {{
                                $t('Dashboard.CreateDocument') }}</a>
                        </li>
                        <li>
                            <a v-on:click="GoTo('/CommingSoon')" href="javascript:void(0);"> {{
                                $t('Dashboard.Reports') }}</a>
                        </li>
                        <li>
                            <a v-on:click="GoTo('/CommingSoon')" href="javascript:void(0);"> {{
                                $t('Dashboard.Setup') }}</a>
                        </li>
                    </ul>
                </li>

                





                <!-- Miscellaneous Menu Bar Code  -->

                <li v-if="role == 'Admin'">

                    <a href="javascript:void(0);">
                        <i class="fas fa-paperclip align-self-center menu-icon text-secondary font-16 align-middle"></i>
                        <span>
                            {{ $t('Dashboard.Miscellaneous') }}
                        </span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span>
                    </a>

                    <ul class="nav-second-level" aria-expanded="false">



                        <li>
                            <a href="javascript:void(0);">
                                <i class="ti-control-record"></i>{{ $t('Dashboard.CloudBackupResotre') }}<span
                                    class="menu-arrow left-has-menu">
                                    <i class="mdi mdi-chevron-right"></i>
                                </span>
                            </a>

                            <ul class="nav-second-level" aria-expanded="false">
                                <li>
                                    <a v-on:click="GoTo('/Backup', 'Purchase_token', 'true')" href="javascript:void(0);"> {{
                                        $t('Dashboard.DataBackup') }}</a>
                                </li>
                                <li>
                                    <a v-on:click="GoTo('/Restore', 'Purchase_token', 'true')" href="javascript:void(0);">
                                        {{ $t('Dashboard.RestoreData') }}</a>
                                </li>

                            </ul>
                        </li>

                    </ul>
                </li>



                <!-- System Management Menu Bar Code  -->

                <li v-if="role == 'Admin'">

                    <a href="javascript:void(0);">
                        <i class="ti-settings text-secondary font-16 align-middle"></i><span>
                            {{ $t('Dashboard.SystemManagement') }}
                        </span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span>
                    </a>
                    <ul class="nav-second-level" aria-expanded="false">
                        <li>
                            <a href="javascript:void(0);">
                                <i class="ti-control-record"></i>PayRoll<span class="menu-arrow left-has-menu">
                                    <i class="mdi mdi-chevron-right"></i>
                                </span>
                            </a>

                            <ul class="nav-second-level" aria-expanded="false">
                                <li>
                                    <a v-on:click="GoTo('/Process&Payment')"
                                        href="javascript:void(0);"> {{ $t('Process & Payment') }}</a>
                                </li>
                                <li>
                                    <a v-on:click="GoTo('/Payables')"
                                        href="javascript:void(0);"> {{ $t('Payables') }}</a>
                                </li>
                                <li>
                                    <a v-on:click="GoTo('/AttendanceReflection')"
                                        href="javascript:void(0);"> {{ $t('Attendance Reflection') }}</a>
                                </li>
                                <li>
                                    <a v-on:click="GoTo('/EarningDifferenceView')"
                                        href="javascript:void(0);"> {{ $t('Earnings Differences View') }}</a>
                                </li>

                            </ul>
                        </li>
                    </ul>
                    

                    <ul class="nav-second-level" aria-expanded="false">

                        
                        
                        <li>
                            <a v-on:click="GoTo('/CompanyProfile')"
                                href="javascript:void(0);"> {{ $t('Dashboard.CompanyInfo') }}</a>
                        </li>
                        <li>
                            <a v-on:click="GoTo('/TransactionLogs', 'Sales_token', 'true')"
                                href="javascript:void(0);">Transaction Logs</a>
                        </li>
                        <li>
                            <a v-on:click="GoTo('/PushPullTransactionLog', 'Sales_token', 'true')"
                                href="javascript:void(0);">Push Pull Transaction Log</a>
                        </li>

                        <li>
                            <a v-on:click="GoTo('/FlushDatabase')"
                                href="javascript:void(0);"> {{ $t('Dashboard.FlushDatabase') }}</a>
                        </li>
                        <li>
                            <a v-on:click="SupervisorLogin" href="javascript:void(0);"> {{ $t('Dashboard.ResetDatabase')
                            }}</a>
                        </li>

                        <li>
                            <a href="javascript:void(0);">
                                <i class="ti-control-record"></i>{{ $t('Dashboard.Synchronization') }}<span
                                    class="menu-arrow left-has-menu">
                                    <i class="mdi mdi-chevron-right"></i>
                                </span>
                            </a>

                            <ul class="nav-second-level" aria-expanded="false">
                                <li>
                                    <a v-on:click="GoTo('/PushRecords')"
                                        href="javascript:void(0);"> {{ $t('Dashboard.PushRecords') }}</a>
                                </li>
                                <li>
                                    <a v-on:click="GoTo('/PullRecords')"
                                        href="javascript:void(0);"> {{ $t('Dashboard.PullRecords') }}</a>
                                </li>
                                <li>
                                    <a v-on:click="GoTo('/Upload')"
                                        href="javascript:void(0);"> {{ $t('Dashboard.ManageFile') }}</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="javascript:void(0);">
                                <i class="ti-control-record"></i>{{ $t('Dashboard.UserSetup') }}<span
                                    class="menu-arrow left-has-menu">
                                    <i class="mdi mdi-chevron-right"></i>
                                </span>
                            </a>

                            <ul class="nav-second-level" aria-expanded="false">
                                <li>
                                    <a v-on:click="GoTo('/signUp', 'Settings And Permission_token', 'true')"
                                        href="javascript:void(0);"> {{ $t('Dashboard.SignUpUser') }}</a>
                                </li>
                                <li>
                                    <a v-on:click="GoTo('/Roles', 'Settings And Permission_token', 'true')"
                                        href="javascript:void(0);"> {{ $t('Dashboard.UserRoles') }}</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    
                </li>

            </ul>

        </div>
    </div>
    <!--end left-sidenav-->

    <div class="page-wrapper">
        <!--Top Bar Start-->
        <div class="topbar">
            <!--Navbar-->
            <nav class="navbar-custom">
                <ul class="list-unstyled topbar-nav float-end mb-0">

                    <li class="dropdown hide-phone">
                        <a class="nav-link dropdown-toggle arrow-none waves-light waves-effect" data-bs-toggle="dropdown"
                            href="javascript:void(0)" role="button" aria-haspopup="false" aria-expanded="false">
                            <i data-feather="search" class="topbar-icon"></i>
                        </a>

                        <div class="dropdown-menu dropdown-menu-end dropdown-lg p-0">
                            <!-- Top Search Bar -->
                            <div class="app-search-topbar">
                                <form action="#" method="get">
                                    <input type="search" name="search" class="from-control top-search mb-0"
                                        placeholder="Type text...">
                                    <button type="submit"><i class="ti-search"></i></button>
                                </form>
                            </div>
                        </div>
                    </li>

                    <li class="dropdown notification-list">
                        <a class="nav-link dropdown-toggle arrow-none waves-light waves-effect" data-bs-toggle="dropdown"
                            href="javascript:void(0)" role="button" aria-haspopup="false" aria-expanded="false">
                            <i data-feather="bell" class="align-self-center topbar-icon"></i>
                            <span class="badge bg-danger rounded-pill noti-icon-badge">2</span>
                        </a>

                    </li>
                    <li class="dropdown" v-if="isHrPortal" hidden>
                        <a class="nav-link dropdown-toggle waves-effect waves-light nav-user pe-auto"
                            v-on:click="PortalFunc(false)" data-bs-toggle="dropdown" href="javascript:void(0)" role="button"
                            aria-haspopup="false" aria-expanded="false">
                            <i class="fas fa-user"></i>
                            HR Portal
                        </a>
                    </li>
                    <li class="dropdown" v-if="!isHrPortal" hidden>
                        <a class="nav-link dropdown-toggle waves-effect waves-light nav-user pe-auto"
                            v-on:click="PortalFunc(true)" data-bs-toggle="dropdown" href="javascript:void(0)" role="button"
                            aria-haspopup="false" aria-expanded="false">

                            <i class="fas fa-user"></i>
                            HR Portal
                        </a>
                    </li>

                    <li class="dropdown">
                        <a class="nav-link dropdown-toggle waves-effect waves-light nav-user" data-bs-toggle="dropdown"
                            href="javascript:void(0)" role="button" aria-haspopup="false" aria-expanded="false">

                            <i class="fas fa-globe"></i>
                            Language
                        </a>
                        <div class="dropdown-menu dropdown-menu-end">
                            <a v-if="english == 'true'" @click="setLocale('en')" class="dropdown-item"
                                href="javascript:void(0)">
                                English
                            </a>
                            <a v-if="arabic == 'true'" @click="setLocale('ar')" class="dropdown-item"
                                href="javascript:void(0)">
                                Arabic
                            </a>
                            <a v-if="portugues == true" @click="setLocale('pt')" class="dropdown-item"
                                href="javascript:void(0)">
                                Portugues
                            </a>

                        </div>
                    </li>

                    <li class="dropdown">
                        <a class="nav-link dropdown-toggle waves-effect waves-light nav-user" data-bs-toggle="dropdown"
                            href="javascript:void(0)" role="button" aria-haspopup="false" aria-expanded="false">
                            <span class="mx-1 nav-user-name hidden-sm">{{ DisplayUserName }}</span>
                            <img src="assets/images/users/user-5.jpg" alt="profile-user" class="rounded-circle thumb-xs" />
                        </a>
                        <div class="dropdown-menu dropdown-menu-end">
                            <a v-on:click="UserProfile" class="dropdown-item" href="javascript:void(0)">
                                <i data-feather="user" class="align-self-center icon-xs icon-dual me-1"></i> {{
                                    $t('Dashboard.MyProfile')
                                }}
                            </a>
                            <div class="dropdown-divider mb-0"></div>
                            <a class="dropdown-item" href="javascript:void(0)" v-on:click="logout()">
                                <i data-feather="power" class="align-self-center icon-xs icon-dual me-1"></i> {{
                                    $t('Dashboard.LogOut')
                                }}
                            </a>
                        </div>
                    </li>
                </ul>

                <ul class="list-unstyled topbar-nav mb-0">
                    <li>
                        <button class="nav-link button-menu-mobile">
                            <i data-feather="menu" class="align-self-center topbar-icon"></i>
                        </button>
                    </li>

                </ul>
            </nav>
            <!--end navbar-->
        </div>
        <!--Top Bar End-->
        <!--Page Content-->
        <div class="page-content">
            <router-view @update:modelValue="logoutUser"></router-view>
            <div v-if="dashboard == '/dashboard'">
                <dashboard></dashboard>
            </div>

            <footer class="footer text-center text-sm-start">
                <span>
                    &copy;
                    2022 <a href="https://www.techqode.com/" target="_blank" class="fw-normal">Noble-Pos (Pvt) Ltd.</a>
                </span>

                <span class="text-muted d-none d-sm-inline-block float-end">
                    Version 1.2.9.5 Last Updated September 04, 2023
                </span>
            </footer>
        </div>
    </div>
    <supervisor-login-model @close="onCloseEvent" :show="show" :isFlushData="true" :isReset="true" v-if="show" />
    <loading v-model:active="loading" :can-cancel="true" :is-full-page="true"></loading>
</template>

<script>
import clickMixin from '@/Mixins/clickMixin'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';


export default {
    mixins: [clickMixin],
    name: 'locale-changer',
    components: {
        Loading
    },
    data() {
        return {
            mainBranch: false,
            isHrPortal: false,
            aboutUsDetail: '',
            createDocument: false,
            loading: false,
            IsDailyExpense: false,
            noblePermissions: '',
            paymentLimitInterval: '',
            companyId: '',
            langs: ['en', 'ar', 'pt'],
            invoiveItem: false,
            invoiveBarCode: false,
            invoiveBarCodeItem: false,
            saleOrderPerm: false,
            WholeSale: '',
            purchaseOrder: false,
            isMouseover: false,
            expenseBill: false,
            IsExpenseAccount: false,
            DisplayUserName: '',
            role: '',
            dashboard: '',
            ur: '',
            isAccount: '',
            isDayStart: '',
            arabic: '',
            english: '',
            isMasterProduct: false,
            nobleRole: '',
            show: false,
            loginHistory: {
                userId: '',
                isLogin: false,
                companyId: ''
            },
            dayStart: '',
            propValvue: '',
            saleMenu: false,
            inventoryMenu: false,
            wareHouseMenu: false,
            startOperationMenu: false,
            startOpSetupMenu: false,
            accountMenu: false,
            purchaseMenu: false,
            settingMenu: false,
            humanMenu: false,
            financialMenu: false,
            contractMenu: false,
            logisticMenu: false,
            importExportMenu: false,
            systemMenu: false,
            productionMenu: false,
            inquiryMenu: false,
            portugues: false,
            leftToRight: false,

            importExportSale: false,
            IsSimpleInvoice: false,
            AllSale: false,
            applicationName: '',
            branchName: '',
            allowBranches: false,
            photo:'',
        }
    },
    methods: {
        PortalFunc: function (val) {
            if (val == true) {

                localStorage.setItem('HrPortal', true)
                this.$router.push('/StartScreen');
                this.$router.go();
            }
            if (val == false) {

                localStorage.setItem('HrPortal', false)
                this.$router.push('/StartScreen');
                this.$router.go();
            }
        },
        GoTo: function (link, token, fromDashboard, formName, fromService) {

            localStorage.setItem('IsService', fromService);

            this.$router.push({
                path: link,
                query: {
                    token_name: token,
                    fromDashboard: fromDashboard,
                    formName: formName,
                    fromService: fromService,
                }
            });
        },

        StartScreen: function () {
            this.$router.push('/StartScreen')
        },
        commingSoonPage: function () {
            this.$router.push('/CommingSoon')
        },

        AddSalaryTemplate: function () {

            var root = this;
            var token = '';
            /*if (token == '') {*/
            token = localStorage.getItem('token');
            /*}*/
            root.$https.get('/Payroll/ChecKPaySchedule', {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
                .then(function (response) {
                    if (response.data > 0) {
                        root.$router.push('/AddRunPayroll');
                    } else {
                        root.$swal({
                            title: 'Warning!',
                            text: 'Please publish the opened payrolls before running the next payroll.',
                            type: 'warning',
                            confirmButtonClass: "btn btn-warning",
                            icon: 'warning',
                            timer: 1500,
                            timerProgressBar: true,
                        });
                    }
                },
                    function (error) {
                        root.loading = false;
                        console.log(error);
                    });

        },
        logoutUser: function (user) {

            if (user == 'ManualAttendance') {

                user = 'ManualAttendance';
            } else if (user == 'EmployeeTodayAttendence') {

                user = 'EmployeeTodayAttendence';
            } else if (user == 'purchase') {

                localStorage.removeItem("fromDate");
                localStorage.removeItem("toDate");
                localStorage.removeItem("active");
                localStorage.removeItem("currentPage");
            } else {
                localStorage.removeItem("fromDate");
                localStorage.removeItem("toDate");
                localStorage.removeItem("active");
                localStorage.removeItem("currentPage");
            }
            this.propValvue = user;
            this.saleMenu = false;
            this.inventoryMenu = false;
            this.wareHouseMenu = false;
            this.startOperationMenu = false;
            this.startOpSetupMenu = false;
            this.purchaseMenu = false;
            this.accountMenu = false;
            this.settingMenu = false;
            this.humanMenu = false;
            this.financialMenu = false;
            this.contractMenu = false;
            this.logisticMenu = false;
            this.importExportMenu = false;
            this.systemMenu = false;
            this.productionMenu = false;
            this.inquiryMenu = false;



            if (this.propValvue == 'StartOperation' ||
                this.propValvue == 'StartOperationSetup' ||
                this.propValvue == 'StartOperationReport') {
                this.startOperationMenu = true;
            }
            if (this.propValvue == 'UserSetup' ||
                this.propValvue == 'CounterSetup' ||
                this.propValvue == 'AdditionalSetup') {
                this.startOpSetupMenu = true;
            }



            if (this.propValvue == 'EmployeeRegistration' ||
                this.propValvue == 'AddEmployeeRegistration' ||
                this.propValvue == 'City' ||
                this.propValvue == 'GeographicalSetup' ||
                this.propValvue == 'Region') {
                this.humanMenu = true;
            }
            if (this.propValvue == 'EmployeeRegistration' ||
                this.propValvue == 'AddEmployeeRegistration' ||
                this.propValvue == 'City' ||
                this.propValvue == 'GeographicalSetup' ||
                this.propValvue == 'Region') {
                this.financialMenu = true;
            }
            if (this.propValvue == 'EmployeeRegistration' ||
                this.propValvue == 'AddEmployeeRegistration' ||
                this.propValvue == 'City' ||
                this.propValvue == 'GeographicalSetup' ||
                this.propValvue == 'Region') {
                this.contractMenu = true;
            }
            if (this.propValvue == 'ManualAttendance') {
                this.humanMenu = true;
            }
            if (this.propValvue == 'EmployeeTodayAttendence') {
                this.humanMenu = true;
            }
            if (this.propValvue == 'AttendanceReport') {
                this.humanMenu = true;
            }
            if (this.propValvue == 'AddHolidayOfMonth') {
                this.humanMenu = true;
            }

            if (this.propValvue == 'LogisticsListTransporter' ||
                this.propValvue == 'AddLogisticsTransporter' ||
                this.propValvue == 'LogisticsListClearanceAgent' ||
                this.propValvue == 'AddLogisticsClearanceAgent' ||
                this.propValvue == 'AddLogisticsShippingLinear' ||
                this.propValvue == 'LogisticsListShippingLinear') {
                this.logisticMenu = true;
            }
            if (this.propValvue == 'CompanyProfile' ||
                this.propValvue == 'Restore' ||
                this.propValvue == 'PushRecords' ||
                this.propValvue == 'PullRecords' ||
                this.propValvue == 'FlushDatabase' ||
                this.propValvue == 'Terminal' ||
                this.propValvue == 'BankPosTerminal' ||
                this.propValvue == 'MultiBarcodePrinting' ||
                this.propValvue == 'RackBarcodeCreate' ||
                this.propValvue == 'ApplicationUpdate' ||
                this.propValvue == 'Backup' || this.propValvue == 'aboutUs') {
                this.systemMenu = true;
            }

            localStorage.removeItem("BarcodeScan");
        },
        SupervisorLogin: function () {
            this.show = true;
        },
        onCloseEvent: function (flag) {
            if (flag) {
                this.flush()
            }
            this.show = false
        },
        flush: function () {

            var root = this;
            this.loading = true;
            var token = '';
            if (token == '') {
                token = localStorage.getItem('token');
            }
            root.loading = true;
            var records = 'Reset'
            root.$https
                .get('/System/FlushRecords?records=' + records, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }).then(function (response) {
                    if (response.status == 200) {
                        root.logout();
                        root.$swal({
                            title: "Success!",
                            text: "Flush data successfully",
                            type: 'error',
                            confirmButtonClass: "btn btn-Success",
                            buttonStyling: false,

                        });

                    }
                    root.loading = false;

                }).catch(error => {
                    console.log(error)
                    root.$swal.fire({
                        icon: 'error',
                        title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Something Went Wrong!' : 'هل هناك خطب ما!',
                        text: error.response.data,
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });
                    root.loading = false;
                });
        },
        setLocale(locale) {

            this.$i18n.locale = locale;

            localStorage.setItem('locales', locale);
            this.$router.go(this.$router.currentRoute.fullPath)

        },

        UserProfile: function () {
            this.$router.push('/RegisterUser');
        },
        logoutHistorySave: function () {

            this.loginHistory.userId = localStorage.getItem('UserID')
            this.loginHistory.companyId = localStorage.getItem('CompanyID')
            this.$https.post('/account/LoginHistory', this.loginHistory).then(function (response) {
                if (response.data == 1)
                    console.log('Logout History save done');
                else
                    console.log('Logout History not save due to some error ' + response.data);
            });
        },
        logout: function () {
            var root = this;
            var url = '/account/logout';
            this.$https.post(url, this.login).then(function (response) {

                if (response.data == "Success") {
                    root.logoutHistorySave();

                    var getLocale = localStorage.getItem('locales');

                    root.$store.logout();
                    localStorage.clear();

                    localStorage.setItem('locales', getLocale);

                    root.$router.push('/')

                } else {
                    root.$swal.fire({
                        icon: 'error',
                        title: 'Error Logging Out'
                    });
                }

            });

        },
        GetAboutUsDetail: function () {
            var root = this;
            var token = '';
            /*if (token == '') {*/
            token = localStorage.getItem('token');
            /* }*/
            root.$https.get('Company/AboutUs', {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }).then(function (response) {
                if (response.data.isSuccess) {
                    root.aboutUsDetail = response.data.message
                    root.loading = false;
                }
                root.loading = false;
            });
        },
        GetEmployeeContractData: function (Id) {

            if (this.isContract == false) {
                return;
            }
            var root = this;
            var token = '';
            if (token == '') {
                token = localStorage.getItem('token');
            }
            root.$https.get('EmployeeRegistration/EmployeeContractView?Id=' + Id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                if (response.data != null) {
                    root.id = response.data.id;
                    root.employeeId = response.data.employeeId;
                    root.photo = response.data.photo;
                    debugger; //eslint-disable-line
                    if (root.photo == "") {
                        root.photo = '/images/user-image.jpg'
                    }
                    
                }
            
            
            });

            },
    },
    created() {
        // this.GetAboutUsDetail()
        this.portugues = localStorage.getItem('Portugues') == "true" ? true : false;
        this.leftToRight = localStorage.getItem('LeftToRight') == "true" ? true : false;
        this.isHrPortal = localStorage.getItem('HrPortal') == "true" ? true : false;
        this.applicationName = localStorage.getItem('ApplicationName')
        
        this.role = localStorage.getItem('RoleName');
        var userId = localStorage.getItem('EmployeeId');
        if (this.role == 'HrUser') {
            this.GetEmployeeContractData(userId);
         }
    },
    mounted: function () {

        this.companyId = localStorage.getItem('CompanyID')

        /*if (token == '') {*/

        this.IsExpenseAccount = localStorage.getItem('IsExpenseAccount') == 'true' ? true : false;

        this.WholeSale = localStorage.getItem('BankDetail');

        this.DisplayUserName = localStorage.getItem('UserName');
        this.role = localStorage.getItem('RoleName');
        this.isAccount = localStorage.getItem('isAccount');
        this.dashboard = this.$router.options.routes;

        this.nobleRole = localStorage.getItem('NobleRole');


        /*   if (!token == '') {*/
        /*  this.$router.push('/')*/

        this.english = localStorage.getItem('English');
        this.arabic = localStorage.getItem('Arabic');

    },

}
</script>