import { createApp, defineAsyncComponent } from 'vue'
import { useMainStore } from '@/store/index'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import { createPinia } from "pinia";

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const app = createApp(App);
import BootstrapVueNext from 'bootstrap-vue-next'
app.use(BootstrapVueNext)
app.use(VueSweetalert2);
import piniaPluginPersistedState from "pinia-plugin-persistedstate"
const pinia = createPinia();
pinia.use(piniaPluginPersistedState)
app.use(pinia);


import moment from 'moment'
import axios from 'axios'

import ElementPlus from 'element-plus'
import en from 'element-plus/dist/locale/en.mjs'
import ar from 'element-plus/dist/locale/ar.mjs'
import pt from 'element-plus/dist/locale/pt.mjs'


app.use(ElementPlus, { locale: en });
import clickMixin from '@/Mixins/clickMixin';
import json from '../public/Config.json';


app.use(clickMixin);
app.use(moment);

if (i18n.locale === 'en') {
    app.use(ElementPlus, { locale: en });
}
else if (i18n.locale === 'pt') {
    app.use(ElementPlus, { locale: pt });
}
else {
    app.use(ElementPlus, { locale: ar });
}


import VueApexCharts from "vue3-apexcharts";
app.use(VueApexCharts);
app.component('apexchart', VueApexCharts);

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
/*import store from './store'*/
app.use(Loading);

app.component('yeardropdown', defineAsyncComponent(() => import('./components/General/YearDropdown.vue')));
app.component('grouping', defineAsyncComponent(() => import('./components/General/Grouping.vue')));
app.component('departmentmodel', defineAsyncComponent(() => import('./components/Department/AddDepartment.vue')));
app.component('companyprocessdropdown', defineAsyncComponent(() => import('./components/General/CompanyProcessDropdown.vue')));
app.component('salutation-dropdown', defineAsyncComponent(() => import('./components/General/SalutationDropdown.vue')));
app.component('display-name-dropdown', defineAsyncComponent(() => import('./components/General/DisplayNameDropdown.vue')));
app.component('addleavetypes', defineAsyncComponent(() => import('./components/Hr/LeaveManagement/LeaveTypes/AddLeaveTypes.vue')));
app.component('addleaveperiod', defineAsyncComponent(() => import('./components/Hr/LeaveManagement/LeavePeriod/AddLeavePeriod.vue')));
app.component('addworkweek', defineAsyncComponent(() => import('./components/Hr/LeaveManagement/WorkWeek/AddWorkWeek.vue')));
app.component('addholiday', defineAsyncComponent(() => import('./components/Hr/LeaveManagement/Holiday/AddHoliday.vue')));
app.component('addleaverules', defineAsyncComponent(() => import('./components/Hr/LeaveManagement/LeaveRules/AddLeaveRules.vue')));
app.component('addleavegroups', defineAsyncComponent(() => import('./components/Hr/LeaveManagement/LeaveGroups/AddLeaveGroups.vue')));
app.component('addleavegroups', defineAsyncComponent(() => import('./components/Hr/LeaveManagement/LeaveGroups/AddLeaveGroups.vue')));
app.component('addpaidtimeoff', defineAsyncComponent(() => import('./components/Hr/LeaveManagement/PaidTimeOff/AddPaidTimeOff.vue')));
app.component('leavegroupdropdown', defineAsyncComponent(() => import('./components/General/LeaveGroupDropdown')));
app.component('employeeleavedropdown', defineAsyncComponent(() => import('./components/General/EmployeeLeaveDropdown')));
app.component('leavetypedropdown', defineAsyncComponent(() => import('./components/General/LeaveTypeDropdown')));
app.component('leavetypemultidropdown', defineAsyncComponent(() => import('./components/General/LeaveTypeMultiDropdown')));
app.component('annualleavedaydropdown', defineAsyncComponent(() => import('./components/General/AnnualLeaveDayDropdown')));
app.component('leaveperioddropdown', defineAsyncComponent(() => import('./components/General/LeavePeriodDropdown')));
app.component('addRoles', defineAsyncComponent(() => import('./components/UserRoles/AddRoles.vue')));
app.component('addPermissions', defineAsyncComponent(() => import('./components/UserPermissions/AddPermissions.vue')));
app.component('addholidaysetup', defineAsyncComponent(() => import('./components/Hr/ShiftManagement/AddHolidaySetup.vue')));
app.component('denominationSetupmodel', defineAsyncComponent(() => import('./components/DenominationSetup/AddDenominationSetup.vue')));
app.component('bankmodel', defineAsyncComponent(() => import('./components/Bank/AddBank.vue')));
app.component('usersDropdown', defineAsyncComponent(() => import('./components/General/UsersDropdown.vue')));
app.component('acessdenied', defineAsyncComponent(() => import('./components/General/AcessDenied.vue')));
app.component('userRolesDropdown', defineAsyncComponent(() => import('./components/General/UserRolesDropdown.vue')));
app.component('moduleNamesDropdown', defineAsyncComponent(() => import('./components/General/ModuleNamesDropdown.vue')));
app.component('moduleCategoryDropdown', defineAsyncComponent(() => import('./components/General/ModuleCategoryDropdown.vue')));
app.component('moduleNamesDropdownForAdd', defineAsyncComponent(() => import('./components/General/ModuleNamesDropdownForAdd.vue')));
app.component('assignRoleToUsers', defineAsyncComponent(() => import('./components/UserPermissions/AssignRoleToUsers.vue')));
app.component('assignPermissionsToRole', defineAsyncComponent(() => import('./components/UserPermissions/AssignPermissionsToRole.vue')));
app.component('assignPermissionsToRoleForAdd', defineAsyncComponent(() => import('./components/UserPermissions/AssignPermissionsToRoleForAdd.vue')));
app.component('updatePermissionsToRole', defineAsyncComponent(() => import('./components/UserPermissions/UpdatePermissionsToRole.vue')));
app.component('quickemployeemodel', defineAsyncComponent(() => import('./components/EmployeeRegistration/AddQuickEmployee.vue')));
app.component('modal', defineAsyncComponent(() => import('./components/modalcomponent.vue')));
app.component('datepicker', defineAsyncComponent(() => import('./components/DatePicker.vue')));
app.component('monthpicker', defineAsyncComponent(() => import('./components/MonthPicker.vue')));
app.component('timePicker', defineAsyncComponent(() => import('./components/TimePicker.vue')));
app.component('licence-model', defineAsyncComponent(() => import('./components/Company/LicenceModel.vue')));
app.component('ftp-account-detail', defineAsyncComponent(() => import('./components/Company/FtpAccountDetail.vue')));
app.component('licence-history-model', defineAsyncComponent(() => import('./components/Company/LicenceHistoryModel.vue')));
app.component('company-attachment-modal', defineAsyncComponent(() => import('./components/Company/CompanyAttachmentModal.vue')));
app.component('company-attachments', defineAsyncComponent(() => import('./components/Company/CompanyAttachments.vue')));
app.component('branchPrefixModel', defineAsyncComponent(() => import('./components/Company/BranchPrefixes.vue')));
app.component('BranchPrefixesList', defineAsyncComponent(() => import('./components/Company/BranchPrefixesList.vue')));
app.component('perioddropdown', defineAsyncComponent(() => import('./components/General/PeriodDropdown.vue')));
app.component('monthlydropdown', defineAsyncComponent(() => import('./components/General/MonthlyDropdown.vue')));
app.component('employeeDropdown', defineAsyncComponent(() => import('./components/General/EmployeeDropdown.vue')));
app.component('company-dropdown', defineAsyncComponent(() => import('./components/General/CompanyDropdown.vue')));
app.component('developersettingloginmodel', defineAsyncComponent(() => import('./components/Company/DeveloperSettingLoginModel.vue')));
app.component('decimal-to-fixed', defineAsyncComponent(() => import('./components/General/DecimalToFixed.vue')));
app.component('PaymentImageList', defineAsyncComponent(() => import('./components/PaymentImageList.vue')));
app.component('permissionCategoryDropdown', defineAsyncComponent(() => import('./components/General/PermissionCategoryDropdown.vue')));
app.component('dashboard', defineAsyncComponent(() => import('./components/Dashboard/Dashboard.vue')));
app.component('invoicedashboard', defineAsyncComponent(() => import('./components/Dashboard/InvocieDashboard.vue')));
app.component('forgotPassword', defineAsyncComponent(() => import('./views/ForgotPassword.vue')));
app.component('CompanyInfoImage', defineAsyncComponent(() => import('./components/CompanyInfoImage.vue')));
app.component('pushpulllogmodel', defineAsyncComponent(() => import('./components/PushPullTransactionLog/PushPullLogModel.vue')));
app.component('uploadImage', defineAsyncComponent(() => import('./components/UploadImage.vue')));
app.component('select-branches-model', defineAsyncComponent(() => import('./components/Product/SelectBranches.vue')));
app.component('bankPosTerminalmodal', defineAsyncComponent(() => import('./components/BankPosTerminal/AddBankPosTerminal.vue')));
app.component('citymodal', defineAsyncComponent(() => import('./components/City/AddCity.vue')));
app.component('countrydropdown', defineAsyncComponent(() => import('./components/General/CountryDropdown.vue')));
app.component('countryfor-employee-dropdown', defineAsyncComponent(() => import('./components/General/CountryForEmployeeDropdown.vue')));
app.component('city-for-employee-dropdown', defineAsyncComponent(() => import('./components/General/CityForEmployeeDropdown.vue')));
app.component('provincedropdown', defineAsyncComponent(() => import('./components/General/ProvinceDropdown.vue')));
app.component('citydropdown', defineAsyncComponent(() => import('./components/General/CityDropdown.vue')));
app.component('areadropdown', defineAsyncComponent(() => import('./components/General/AreaDropdown.vue')));
app.component('bankdropdown', defineAsyncComponent(() => import('./components/General/BankDropdown.vue')));
app.component('bankAcountDropdown', defineAsyncComponent(() => import('./components/General/BankAcountDropdown.vue')));
app.component('roledropdown', defineAsyncComponent(() => import('./components/General/RolesDropdown.vue')));
app.component('Backup', defineAsyncComponent(() => import('./components/DatabaseBackup/Backup.vue')));
app.component('Restore', defineAsyncComponent(() => import('./components/Restore/Restore.vue')));
app.component('Upload', defineAsyncComponent(() => import('./components/Upload/Upload.vue')));
app.component('Userprofileimage', defineAsyncComponent(() => import('./components/UserProfileImage.vue')));
app.component('add-company-process', defineAsyncComponent(() => import('./components/CompanyProcess/AddCompanyProcess.vue')));
app.component('add-company-action', defineAsyncComponent(() => import('./components/CompanyAction/AddCompanyAction.vue')));
app.component('import-attachment', defineAsyncComponent(() => import('./components/CompanyAction/ImportAttachment.vue')));
app.component('bulk-attachment', defineAsyncComponent(() => import('./components/Attachment/ImportAttachment.vue')));
app.component('attachment-view', defineAsyncComponent(() => import('./components/Attachment/AttachmentView.vue')));
app.component('add-company-option', defineAsyncComponent(() => import('./components/CompanyOptions/AddCompanyOption.vue')));
app.component('day-dropdown', defineAsyncComponent(() => import('./components/General/DayDropdown.vue')));
app.component('add-deduction', defineAsyncComponent(() => import('./components/Hr/Payroll/Deduction/AddDeduction.vue')));
app.component('add-shift', defineAsyncComponent(() => import('./components/Hr/Shift/AddShift.vue')));
app.component('shift-dropdown', defineAsyncComponent(() => import('./components/General/ShiftDropdown.vue')));
app.component('add-contribution', defineAsyncComponent(() => import('./components/Hr/Payroll/Contribution/AddContribution.vue')));
app.component('add-payroll-schedule', defineAsyncComponent(() => import('./components/Hr/Payroll/PayrollSchedule/AddPayrollSchedule.vue')));
app.component('addAllowance', defineAsyncComponent(() => import('./components/Hr/Payroll/Allowance/AddAllowance.vue')));
app.component('AddAllowanceType', defineAsyncComponent(() => import('./components/Hr/Payroll/AllowanceType/AddAllowanceType.vue')));
app.component('allowanceTypeDropdown', defineAsyncComponent(() => import('./components/General/AllowanceTypeDropdown.vue')));
app.component('Weekdropdown', defineAsyncComponent(() => import('./components/General/WeekDaysDropdown.vue')));
app.component('OverTimeDropdown', defineAsyncComponent(() => import('./components/General/OverTimeDropdown.vue')));
app.component('EmployeeOverTimeDropdown', defineAsyncComponent(() => import('./components/General/EmployeeOverTimeDropdown.vue')));
app.component('EmployeeContractDropdown', defineAsyncComponent(() => import('./components/General/EmployeeContractDropdown.vue')));
app.component('allowanceDropdown', defineAsyncComponent(() => import('./components/General/AllowanceDropdown.vue')));
app.component('deductionDropdown', defineAsyncComponent(() => import('./components/General/DeductionDropdown.vue')));
app.component('addDeduction', defineAsyncComponent(() => import('./components/Hr/Payroll/Deduction/AddDeduction.vue')));
app.component('addMonthlyDeduction', defineAsyncComponent(() => import('./components/Hr/Payroll/MonthlyDeduction/AddMonthlyDeduction.vue')));
app.component('contributionDropdown', defineAsyncComponent(() => import('./components/General/ContributionDropdown.vue')));
app.component('salary-template-dropdown', defineAsyncComponent(() => import('./components/General/SalaryTemplateDropdown.vue')));
app.component('payroll-schedule-dropdown', defineAsyncComponent(() => import('./components/General/PayrollScheduleDropdown.vue')));
app.component('departmentModel', defineAsyncComponent(() => import('./components/Hr/Employee/Department/AddDepartment.vue')));
app.component('designationModel', defineAsyncComponent(() => import('./components/Hr/Employee/Designation/AddDesignation.vue')));
app.component('designationDropdown', defineAsyncComponent(() => import('./components/General/DesignationDropdown.vue')));
app.component('designation-multi-dropdown', defineAsyncComponent(() => import('./components/General/DesignationMultiDropdown.vue')));
app.component('department-multi-dropdown', defineAsyncComponent(() => import('./components/General/DepartmentMultiDropdown.vue')));
app.component('departmentDropdown', defineAsyncComponent(() => import('./components/General/DepartmentDropdown.vue')));
app.component('contractTemplateDropdown', defineAsyncComponent(() => import('./components/General/ContractTemplateDropdown.vue')));
app.component('weekScheduleDropdown', defineAsyncComponent(() => import('./components/General/WeekScheduleDropdown.vue')));
app.component('loanDetailModel', defineAsyncComponent(() => import('./components/Hr/Payroll/LoanPayment/LoanDetailModel.vue')));
app.component('loanRecovery', defineAsyncComponent(() => import('./components/Hr/Payroll/LoanPayment/LoanRecoveryModel.vue')));
app.component('salaryTemplateModel', defineAsyncComponent(() => import('./components/Hr/Payroll/RunPayroll/SalaryTemplateModel.vue')));
app.component('run-payroll-model', defineAsyncComponent(() => import('./components/Hr/Payroll/RunPayroll/RunPayrollView.vue')));
app.component('BankMultiDropdown', defineAsyncComponent(() => import('./components/General/BankMultiDropdown.vue')));
app.component('blockmodel', defineAsyncComponent(() => import('./components/Bank/BlockModel.vue')));
app.component('issuedtodropdown', defineAsyncComponent(() => import('./components/General/IssuedToDropdown.vue')));
app.component('AccountDashboard', defineAsyncComponent(() => import('./components/Dashboard/AccountDashboard.vue')));
app.component('HRDashboard', defineAsyncComponent(() => import('./components/Dashboard/HRDashboard.vue')));
app.component('InventoryDashboard', defineAsyncComponent(() => import('./components/Dashboard/InventoryDashboard.vue')));
app.component('CashAndBankDashboard', defineAsyncComponent(() => import('./components/Dashboard/CashAndBankDashboard.vue')));
app.component('CashAndBankDashboard', defineAsyncComponent(() => import('./components/Dashboard/CashAndBankDashboard.vue')));
app.component('PrintSettingImages', defineAsyncComponent(() => import('./components/PrintSettingImages.vue')));    
app.component('TodayAttendenceModel', defineAsyncComponent(() => import('./components/ManualAttendance/AddManualAttendence.vue')));
app.component('addbranches', defineAsyncComponent(() => import('./components/Branches/AddBranches.vue')));
app.component('branch-dropdown', defineAsyncComponent(() => import('./components/General/BranchDropdown.vue')));
app.component('addbranchuser', defineAsyncComponent(() => import('./components/BranchUsers/AddBranchUser.vue')));
app.component('addEmployeeLocation', defineAsyncComponent(() => import('./components/EmployeeLocation/AddEmployeeLocation.vue')));
app.component('addpaymentMethod', defineAsyncComponent(() => import('./components/Payroll/AddPaymentMethod.vue')));
app.component('employeelocationdropdown', defineAsyncComponent(() => import('./components/General/EmployeeLocationDropdown')));
app.component('AddFolder', defineAsyncComponent(() => import('./components/EmployeeRegistration/AddFolder.vue')));
app.component('AddOverTime', defineAsyncComponent(() => import('./components/Hr/ShiftAssign/AddOverTime.vue')));
app.component('OverTime', defineAsyncComponent(() => import('./components/Hr/ShiftAssign/OverTime.vue')));
app.component('addTemplate', defineAsyncComponent(() => import('./components/Template/NewTemplate.vue')));
app.component('template-model', defineAsyncComponent(() => import('./components/Template/TemplateModel.vue')));

app.config.globalProperties.$https = axios;
axios.defaults.baseURL = json.ServerIP;
app.config.globalProperties.$ServerIp = json.ServerIP;
app.config.globalProperties.$PermissionIp = json.PermissionIP;
app.config.globalProperties.$ClientIP = json.ClientIP;
app.config.globalProperties.$ReportServer = json.ReportServer;
app.config.globalProperties.$macAddess = 'D8-FC-93-2E-5B-94';
app.config.globalProperties.$SystemType = json.SystemType;


app.config.globalProperties.$englishLanguage = function (value) {
    var getEnglishLanguage = localStorage.getItem('English');
    var getArabicLanguage = localStorage.getItem('Arabic');
    var getPortuguesLanguage = localStorage.getItem('Portugues');
    if (getEnglishLanguage == 'true' && getArabicLanguage == 'false' && getPortuguesLanguage == 'false') {
        return value;
    }
    else if (getEnglishLanguage == 'false' && getArabicLanguage == 'true' && getPortuguesLanguage == 'false') {
        return value;
    }
    else if (getEnglishLanguage == 'false' && getArabicLanguage == 'false' && getPortuguesLanguage == 'true') {
        return value;
    }

    else {
        var lan = localStorage.getItem('locales');

        if (lan == 'en') {
            return value + ' (English)';
        }
        else if (lan == 'pt') {
            return value + ' (Inglês)';
        }
        else {
            return value + ' (انجليزي)';
        }

    }
};


app.config.globalProperties.$arabicLanguage = function (value) {
    var getEnglishLanguage = localStorage.getItem('English');
    var getArabicLanguage = localStorage.getItem('Arabic');
    var getPortuguesLanguage = localStorage.getItem('Portugues');
    if (getEnglishLanguage == 'true' && getArabicLanguage == 'false' && getPortuguesLanguage == 'false') {
        return value;
    }
    else if (getEnglishLanguage == 'false' && getArabicLanguage == 'true' && getPortuguesLanguage == 'false') {
        return value;
    }
    else if (getEnglishLanguage == 'false' && getArabicLanguage == 'false' && getPortuguesLanguage == 'true') {
        return value;
    }
    else {
        var lan = localStorage.getItem('locales');
        if (lan == 'en' && getArabicLanguage == 'false' && getPortuguesLanguage == 'true') {
            return value + ' (Portugues)';
        }
        else if (lan == 'en' && getArabicLanguage == 'true' && getPortuguesLanguage == 'false') {
            return value + ' (Arabic)';
        }
        else if (lan == 'pt') {
            return value + ' (Portugues)';
        }
        else {
            return value + ' (عربي)';
        }

    }
};

app.config.globalProperties.$formatAmount = function (value) {
    if (!value) return '';
    return parseFloat(value).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
};

app.config.globalProperties.$roundOffFilter = function (value) {
    if (!value) return '';
    return parseFloat(value).toFixed(3).slice(0, -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
};

app.config.globalProperties.$roundAmount = function (value) {
    if (!value) return 0;
    return parseFloat(value).toFixed(3).slice(0, -1);
};

app.config.globalProperties.$store = useMainStore();
app.use(router);
app.use(i18n);


app.mount('#app')
