<template>
    <div class="container">
        <div class="row vh-100 d-flex justify-content-center">
            <div class="col-12 align-self-center">
                <div class="row">
                    <div class="col-lg-5 mx-auto">
                        <div class="card">
                            <div class="card-body p-0 auth-header-box bg-white">
                                <div class="text-center p-3">
                                    <a href="javascript:void(0);" class="logo logo-admin">
                                        <img src="NobleLogin.png" height="50" alt="logo" class="auth-logo">
                                    </a>
                                    <h4 class="mt-3 mb-1 fw-semibold font-18" style="color: #0C213A;">Noble-Pos ERP</h4>
                                    <p class="text-muted  mb-0">{{ $t('login.WelcomePleaselogintoyouraccount') }}</p>
                                </div>
                            </div>
                            <div class="card-body p-0">
                                <div class="tab-content">
                                    <div class="tab-pane active p-3" id="LogIn_Tab" role="tabpanel">
                                        <div class="form-horizontal auth-form">

                                            <div class="form-group mb-2">
                                                <label class="form-label">{{ $t('login.UsernameorEmail') }}</label>
                                                <div class="input-group">
                                                    <input v-model="v$.login.email.$model" type="text" class="form-control"
                                                        @keyup.enter="userlogin"
                                                        :placeholder="$t('login.Enteryourusernameoremail')">
                                                </div>
                                            </div><!--end form-group-->

                                            <div class="form-group mb-2">
                                                <label class="form-label">
                                                    {{ $t('login.RegisterUser_Password') }}
                                                </label>
                                                <div class="input-group ">
                                                    <input v-model="v$.login.password.$model" :type="password"
                                                        class="form-control" @keyup.enter="userlogin"
                                                        :placeholder="$t('login.EnteryourPassword')">
                                                    <div style=" position: absolute; top: 24%; " @click="showPassword"
                                                        v-bind:style="($i18n.locale == 'en' || $i18n.locale == 'pt' || isLeftToRight()) ? 'left: 94%' : 'left: 11px'">
                                                        <i class="fas fa-eye" v-if="eyeValue == false"></i>
                                                        <i class="fas fa-eye-slash" v-if="eyeValue == true"></i>
                                                    </div>
                                                </div>

                                            </div><!--end form-group-->

                                            <div class="form-group row my-3">
                                                <div class="col-sm-6">
                                                    <div class="custom-control custom-switch switch-success">
                                                        <input v-model="login.rememberMe" type="checkbox"
                                                            class="custom-control-input" id="customSwitchSuccess">
                                                        <label class="form-label text-muted mx-1" for="customSwitchSuccess">
                                                            {{
                                                                $t('login.Rememberme')
                                                            }}
                                                        </label>
                                                    </div>
                                                </div><!--end col-->
                                                <div class="col-sm-6 text-end">
                                                    <a href="javascript:void(0)" v-on:click="openmodel"
                                                        class="text-muted font-13">
                                                        <i class="dripicons-lock"></i> {{
                                                            $t('login.ForgotPassword?')
                                                        }}
                                                    </a>
                                                </div><!--end col-->
                                            </div><!--end form-group-->

                                            <div class="form-group mb-0 row">
                                                <div class="col-12">
                                                    <small class="text-danger">{{ customError }}</small>
                                                    <button v-on:click="userlogin"
                                                        class="btn btn-primary w-100 waves-effect waves-light"
                                                        type="button">
                                                        <i v-if="loading"
                                                            class="la la-refresh text-secondary la-spin progress-icon-spin"
                                                            style="font-size:.8125rem;"></i>
                                                        <span v-else>
                                                            {{ $t('login.Login1') }} <i class="fas fa-sign-in-alt ms-1"></i>
                                                        </span>
                                                    </button>
                                                </div><!--end col-->
                                            </div> <!--end form-group-->
                                        </div><!--end form-->
                                        <!--<div class="m-3 text-center text-muted">
                                                <p class="mb-0">Don't have an account ?  <a href="auth-register.html" class="text-primary ms-2">Free Resister</a></p>
                                                </div>-->
                                        <div class="account-social">
                                            <h6 class="mb-3 " style="color:transparent;">-</h6>
                                        </div>
                                        <div class="btn-group w-100">
                                            <button type="button" class="btn btn-sm btn-outline-secondary">
                                                Support
                                            </button>
                                            <button type="button" class="btn btn-sm btn-outline-secondary">
                                                Contact
                                                Us
                                            </button>
                                            <button type="button" class="btn btn-sm btn-outline-secondary">
                                                Visit
                                                Us
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div><!--end card-body-->
                            <div class="card-body bg-light-alt text-center">
                                <span class="text-muted d-none d-sm-inline-block">
                                    <small>Version 1.2.9.5 Last Updated September 04, 2023. Noble-Pos (Pvt) Ltd.</small>
                                </span>
                            </div>
                        </div><!--end card-->
                    </div><!--end col-->
                </div><!--end row-->
            </div><!--end col-->
        </div><!--end row-->
        <forgotPassword :show="show" v-if="show" @close="show = false" />
    </div><!--end container-->
</template>
<script>
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators';

export default {
    setup() {
        return { v$: useVuelidate() }
    },
    name: 'login-component',
    data() {
        return {
            isPortuguese: false,
            langs: ['en', 'ar'],
            login: {
                email: '',
                password: '',
                rememberMe: true
            },
            password: "password",
            eyeValue: false,
            roles: [],
            open: false,
            website: 'https://www.techqode.com/',
            error: '',
            show: false,
            customError: '',
            loading: false,
            isEmployee: true,
            isAccount: false,
            IsProceed: false,
            heading: '',
            description: '',
            companyName: '',
            applicationName: '',
            email: '',
            favName: '',
            terms: false,
            os: '',
            loginHistory: {
                userId: '',
                isLogin: true,
                operatingSystem: '',
                companyId: ''
            },
            isPayment: false,
            expirationMsg: '',

        }
    },
    validations() {
        return {
            login:
            {
                email:
                {
                    required
                },
                password:
                {
                    required
                }
            }
        }
    },

    mounted() {

        this.getOs();
    },
    methods: {

        isLeftToRight: function () {
            console.log('dsfsdfs')
        },


        showPassword() {
            if (this.password === "password") {
                this.password = "text";
                this.eyeValue = true;
            }
            else {
                this.password = "password"
                this.eyeValue = false;
            }

        },
        hidepassword() {
            this.password = "password"
            this.eyeValue = false;
        },
        setLocale(locale) {

            this.$i18n.locale = locale
            localStorage.setItem('locales', locale);
        },

        gotoWebSite: function () {
            window.location.href = this.website;
        },

        openmodel: function () {
            this.show = !this.show;
        },

        loginHistorySave: function () {

            this.$https.post('/account/LoginHistory', this.loginHistory).then(function (response) {
                if (response.data == 1)
                    console.log('Logout History save done');
                else
                    console.log('Logout History not save due to some error ' + response.data);
            });
        },
        userlogin: function () {
            var root = this;
            root.customError = '';
            root.loading = true;
            var url = '/account/login';

            this.$https.post(url, this.login).then(function (response) {
debugger; //eslint-disable-line
                if (response.data == true) {
                    root.$router.push({
                        path: '/Authenticator',
                        query: { data: root.login }
                    })

                }
                else {
                    document.cookie.split(';').forEach(cookie => document.cookie = cookie.replace(/^ +/, '').replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`));
                    //root.clearCookie()
                    //if (root.login.rememberMe) {
                    //    root.setCookie();
                    //} else {
                    //    document.cookie.split(';').forEach(cookie => document.cookie = cookie.replace(/^ +/, '').replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`));
                    //}
                    if (response.data.companyId == "00000000-0000-0000-0000-000000000000") {

                        root.loading = false;
                        root.customError = 'Invalid Login Attempt.';

                    }
                    else if (response.data.isUseMachine) {
                        root.$store.login(this.login.email);
                        localStorage.setItem('CompanyID', response.data.companyId);
                        localStorage.setItem('token', response.data.token);
                  
                        root.$router.push({
                            path: '/NotPermission',
                            query: { data: response.data.expiration, machine: true }
                        });
                    }
                    else if (response.data.isNotPayment) {
                        root.$store.login(this.login.email);
                        localStorage.setItem('CompanyID', response.data.companyId)
                        localStorage.setItem('token', response.data.token);
                        root.$router.push({
                            path: '/NotPermission',
                            query: { data: response.data.expiration }
                        });
                    }
                    else if (response.data.expiration != "" && response.data.expiration != null && response.data.isNotPayment == false && response.data.isPayment == false) {
                        root.$store.login(this.login.email);
                        localStorage.setItem('CompanyID', response.data.companyId)
                        localStorage.setItem('token', response.data.token);
                        root.$router.push({
                            path: '/NotPermission',
                            query: { data: response.data.expiration }
                        });
                    }

                    else {

                        root.loginHistory.userId = response.data.userId;
                        root.loginHistory.companyId = response.data.companyId;
                        root.loginHistorySave();

                        root.isPayment = response.data.isPayment
                        root.expirationMsg = response.data.expiration
                        var getLocale = localStorage.getItem('locales');
                        root.$store.login(true);
                        localStorage.clear();

                        localStorage.setItem('locales', getLocale);

                        localStorage.setItem('token', response.data.token);
                        localStorage.setItem('UserId', response.data.userId);
                        localStorage.setItem('UserName', response.data.userName);
                        localStorage.setItem('overWrite', response.data.overWrite);
                        localStorage.setItem('LoginUserName', response.data.loginUserName);
                        localStorage.setItem('RoleName', response.data.roleName);
                        localStorage.setItem('ImagePath', response.data.imagePath);
                        localStorage.setItem('CompanyID', response.data.companyId);
                        localStorage.setItem('UserID', response.data.userId);
                        localStorage.setItem('FullName', response.data.fullName);
                        localStorage.setItem('AllowAll', response.data.allowAll);
                        localStorage.setItem('CounterId', response.data.counterId);
                        localStorage.setItem('IsSupervisor', response.data.isSupervisor);
                        localStorage.setItem('TermsCondition', response.data.termsCondition);


                        localStorage.setItem('NobleRole', response.data.nobleRole);
                        localStorage.setItem('English', true);
                        localStorage.setItem('Arabic', true);
                        localStorage.setItem('BusinessLogo', response.data.businessLogo);
                        localStorage.setItem('BusinessNameArabic', response.data.businessNameArabic);
                        localStorage.setItem('BusinessNameEnglish', response.data.businessNameEnglish);
                        localStorage.setItem('BusinessTypeArabic', response.data.businessTypeArabic);
                        localStorage.setItem('BusinessTypeEnglish', response.data.businessTypeEnglish);
                        localStorage.setItem('CompanyNameArabic', response.data.companyNameArabic);
                        localStorage.setItem('CompanyNameEnglish', response.data.companyNameEnglish);

                        root.terms = response.data.termsCondition;

                        localStorage.setItem('TermAndConditionLength', response.data.termAndConditionLength);
                        localStorage.setItem('OnPageLoadItem', response.data.onPageLoadItem);

                        localStorage.setItem('BranchId', response.data.branchId == null ? '' : response.data.branchId);
                        localStorage.setItem('BranchName', response.data.branchName);
                        localStorage.setItem('MainBranch', response.data.mainBranch);

                        if (response.data.employeeId != null) {
                            /*   root.$router.push('/daystart')*/
                            localStorage.setItem('EmployeeId', response.data.employeeId);
                        }

                        /*iSProceed*/
                        localStorage.setItem('isProceed', response.data.isProceed);
                        if (response.data.isProceed) {
                            root.IsProceed = true;
                        }
                        if (response.data.phoneNo != null) {
                            localStorage.setItem('PhoneNo', response.data.phoneNo);
                            root.isEmployee = false;
                            // root.GetData();
                        }
                        if (response.data.roleName == 'User') {
                            root.$router.push('/StartScreen');
                        }
                        if (response.data.roleName == 'HrUser') {
                            root.$router.push('/StartScreen');
                        }
                        if (response.data.roleName == 'Noble Admin') {
                            root.$router.push('/StartScreen');
                        }
                        if (response.data.roleName == 'Admin') {
                            root.$router.push('/StartScreen');
                        }



                    }
                }

            }).catch(error => {
                root.customError = JSON.stringify(error.response.data.error);
                root.loading = false;
            })
        },

        GetData: function () {

            if (this.IsProceed == false) {
                this.isAccount = true;
                localStorage.setItem("isAccount", this.isAccount);
                if (this.terms == true) {
                    this.$router.push('/setup');

                }
                else {
                    this.$router.push('/TermsAndConditions');

                }
            }

            else {

                this.$router.push('/StartScreen');
            }
        },

        getOs: function () {
            var userAgent = window.navigator.userAgent,
                platform = window.navigator.platform,
                macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
                windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
                iosPlatforms = ['iPhone', 'iPad', 'iPod'],
                os = null;

            if (macosPlatforms.indexOf(platform) !== -1) {
                os = 'Mac OS';
            } else if (iosPlatforms.indexOf(platform) !== -1) {
                os = 'iOS';
            } else if (windowsPlatforms.indexOf(platform) !== -1) {
                os = 'Windows';
            } else if (/Android/.test(userAgent)) {
                os = 'Android';
            } else if (!os && /Linux/.test(platform)) {
                os = 'Linux';
            }
            this.loginHistory.operatingSystem = os;
        }
    },
    created: function () {

        console.log('console');
    }
}
</script>

