//import { h } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
//import { stringifyQuery } from 'vue-router'
import { useMainStore } from '@/store/index'
import { defineAsyncComponent } from 'vue'


import Login from '../views/Login.vue';
import Dashboard from '../views/Dashboard.vue';
import NewPassword from '../views/NewPassword.vue';
import ResetPassword from '../views/ResetPassword.vue';
import Navbar from '../views/Navbar.vue';
import sync from '../views/Sync.vue';
import CommingSoon from '../views/CommingSoon.vue';



// import App from '../App.vue'

const routes = [

    {
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/NewPassword',
        name: 'NewPassword',
        component: NewPassword
    },
    {
        path: '/ResetPassword',
        name: 'ResetPassword',
        component: ResetPassword
    },
    {
        path: '/Welcome',
        name: 'Welcome',
        component: defineAsyncComponent(() => import('../components/UserScreen/Welcome.vue')),
    },
    {
        path: '/TermDashboard',
        name: 'TermDashboard',
        component: defineAsyncComponent(() => import('../components/Dashboard/TermsDashboard.vue')),
        children: [
            {
                path: '/Support',
                name: 'Support',
                component: defineAsyncComponent(() => import('../views/Support.vue'))
            },
            {
                path: '/CompanyInfo',
                name: 'CompanyInfo',
                component: defineAsyncComponent(() => import('../components/CompanyProfile/CompanyInfo.vue')),
            },
            {
                path: '/FinancialYearSetup',
                name: 'FinancialYearSetup',
                component: defineAsyncComponent(() => import('../components/FinancialYear/FinancialYearSetup.vue')),
            }
        ]
    },

    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        children: [
           
            
            {
                path: '/navbar',
                name: 'Navbar',
                component: Navbar
            },
            {
                path: '/Company',
                name: 'Company',
                component: defineAsyncComponent(() => import('../components/Company/Company.vue'))
            },
            {
                path: '/CompanyProfile',
                name: 'CompanyProfile',
                component: defineAsyncComponent(() => import('../components/CompanyProfile/CompanyProfile.vue'))
            },
            {
                path: '/Shifts&WorkingHours',
                name: 'Shifts&WorkingHours',
                component: defineAsyncComponent(() => import('../components/Shifts&WorkingHours/Shifts&WorkingHours.vue'))
            },
            {
                path: '/LeaveTypes',
                name: 'LeaveTypes',
                component: defineAsyncComponent(() => import('../components/LeaveTypes/LeaveTypes.vue'))
            },
            {
                path: '/PublicHolidays',
                name: 'PublicHolidays',
                component: defineAsyncComponent(() => import('../components/LeaveTypes/PublicHolidays.vue'))
            },
            {
                path: '/Process&Payment',
                name: 'Process&Payment',
                component: defineAsyncComponent(() => import('../components/Payroll/Process&Payment.vue'))
            },
            {
                path: '/Payables',
                name: 'Payables',
                component: defineAsyncComponent(() => import('../components/Payroll/Payables.vue'))
            },
            {
                path: '/AttendanceReflection',
                name: 'AttendanceReflection',
                component: defineAsyncComponent(() => import('../components/Payroll/AttendanceReflection.vue'))
            },
            {
                path: '/EarningDifferenceView',
                name: 'EarningDifferenceView',
                component: defineAsyncComponent(() => import('../components/Payroll/EarningDifferenceView.vue'))
            },
            {
                path: '/Requests&Approvals',
                name: 'Requests&Approvals',
                component: defineAsyncComponent(() => import('../components/Requests/Requests&Approvals.vue'))
            },
            {
                path: '/LeaveAssigning',
                name: 'LeaveAssigning',
                component: defineAsyncComponent(() => import('../components/LeaveAssigning/LeaveAssigning.vue'))
            },
            {
                path: '/EmployeeLeaveRequest',
                name: 'EmployeeLeaveRequest',
                component: defineAsyncComponent(() => import('../components/EmployeeRequests/EmployeeLeaveRequest.vue'))
            },
            {
                path: '/EmployeeOverTimeRequest',
                name: 'EmployeeOverTimeRequest',
                component: defineAsyncComponent(() => import('../components/EmployeeRequests/EmployeeOverTimeRequest.vue'))
            },
            {
                path: '/RequestApprover',
                name: 'RequestApprover',
                component: defineAsyncComponent(() => import('../components/RequestApprover/RequestApprover.vue'))
            },
            {
                path: '/Branches',
                name: 'Branches',
                component: defineAsyncComponent(() => import('../components/Branches/Branches.vue')),
            },
            {
                path: '/BranchesPermission',
                name: 'BranchesPermission',
                component: defineAsyncComponent(() => import('../components/Branches/BranchesPermission.vue')),
            },
            {
                path: '/BranchUsers',
                name: 'BranchUsers',
                component: defineAsyncComponent(() => import('../components/BranchUsers/BranchUsers.vue')),

            },
            {
                path: '/CompanyAttachments',
                name: 'CompanyAttachments',
                component: defineAsyncComponent(() => import('../components/Company/CompanyAttachments.vue'))
            },

            {
                path: '/superadmin',
                name: 'Superadmin',
                component:  defineAsyncComponent(() => import('../components/Dashboard/SuperAdmin.vue'))
            },

            {
                path: '/addcompany',
                name: 'AddCompany',
                component: defineAsyncComponent(() => import('../components/Company/AddCompany.vue'))
            },


            {
                path: '/addbusiness',
                name: 'AddBusiness',
                component: defineAsyncComponent(() => import('../components/Business/AddBusiness.vue'))
            },


            {
                path: '/business',
                name: 'Business',
                component: defineAsyncComponent(() => import('../components/Business/Business.vue'))
            },
            {
                path: '/location',
                name: 'Location',
                component: defineAsyncComponent(() => import('../components/Location/Location.vue'))
            },
            {
                path: '/addLocation',
                name: 'AddLocation',
                component: defineAsyncComponent(() => import('../components/Location/AddLocation.vue'))
            },
            {
                path: '/companyAdditionalInfo',
                name: 'CompanyAdditionalInfo',
                component:  defineAsyncComponent(() => import('../components/Company/CompanyAdditionalInfo.vue'))
            },
            {
                path: '/companyAdditionalList',
                name: 'CompanyAdditionalList',
                component:  defineAsyncComponent(() => import('../components/Company/CompanyAdditionalList.vue'))
            },

           
            {
                path: '/userprofile',
                name: 'UserProfileInfo',
                component: defineAsyncComponent(() => import('../components/UserProfileInfo.vue'))
            },
            {
                path: '/RegisterUser',
                name: 'RegisterUser',
                component: defineAsyncComponent(() => import('../components/RegisterUser.vue'))
            },

            
           
         
            {
                path: '/EmployeeTodayAttendence',
                name: 'EmployeeTodayAttendence',
                component: defineAsyncComponent(() => import('../components/ManualAttendance/EmployeeTodayAttendence.vue')),
            },
            
            {
                path: '/employeeRegistration',
                name: 'EmployeeRegistration',
                component: defineAsyncComponent(() => import('../components/EmployeeRegistration/EmployeeRegistration.vue'))
            },
            {
                path: '/EmployeeView',
                name: 'EmployeeView',
                component: defineAsyncComponent(() => import('../components/EmployeeRegistration/EmployeeView.vue'))
            },
            {
                path: '/EmployeeContractView',
                name: 'EmployeeContractView',
                component: defineAsyncComponent(() => import('../components/EmployeeRegistration/EmployeeContractView.vue'))
            },
            {
                path: '/addEmployeeRegistration',
                name: 'AddEmployeeRegistration',
                component: defineAsyncComponent(() => import('../components/EmployeeRegistration/AddEmployeeRegistration.vue'))
            },
            {
                path: '/employeeDraft',
                name: 'EmployeeDraft',
                component: defineAsyncComponent(() => import('../components/EmployeeRegistration/EmployeeDraft.vue'))
            },
            {
                path: '/addEmployeeDraft',
                name: 'AddEmployeeDraft',
                component: defineAsyncComponent(() => import('../components/EmployeeRegistration/AddEmployeeDraft.vue'))
            },
            {
                path: '/addEmployeeContract',
                name: 'addEmployeeContract',
                component: defineAsyncComponent(() => import('../components/EmployeeRegistration/AddEmployeeContract.vue'))
            },
            {
                path: '/companyEmployee',
                name: 'CompanyEmployee',
                component: defineAsyncComponent(() => import('../components/EmployeeRegistration/CompanyEmployee.vue'))
            },
            {
                path: '/employeeLocation',
                name: 'EmployeeLocation',
                component: defineAsyncComponent(() => import('../components/EmployeeLocation/EmployeeLocation.vue'))
            },
            {
                path: '/folder',
                name: 'Folder',
                component: defineAsyncComponent(() => import('../components/EmployeeRegistration/Folder.vue'))
            },
            {
                path: '/ContractTemplate',
                name: 'ContractTemplate',
                component: defineAsyncComponent(() => import('../components/Template/TemplateList.vue'))
            },
            {
                path: '/OverTime',
                name: 'OverTime',
                component: defineAsyncComponent(() => import('../components/Hr/ShiftAssign/OverTime.vue'))
            },
            {
                path: '/ContractTemplateView',
                name: 'ContractTemplateView',
                component: defineAsyncComponent(() => import('../components/Template/NewTemplate.vue'))
            },
            {
                path: '/addSignUp',
                name: 'AddSignUp',
                component: defineAsyncComponent(() => import('../components/LoginCredentials/AddSignUp.vue'))
            },

            
            {
                path: '/ClientManagement',
                name: 'clientManagement',
                component: defineAsyncComponent(() => import('../components/Company/ClientManagement.vue'))
            },
            {
                path: '/addAccountSetup',
                name: 'AddAccountSetup',
                component: defineAsyncComponent(() => import('../components/CompanyAccountSetup/AddAccountSetup.vue'))
            },
            {
                path: '/AddBank',
                name: 'AddBank',
                component: defineAsyncComponent(() => import('../components/Bank/AddBank.vue'))
            },

            {
                path: '/Dashboard1',
                name: 'Dashboard1',
                component: defineAsyncComponent(() => import('../components/Dashboard/Dashboard.vue'))
            },

           

            {
                path: '/AddRoles',
                name: 'AddRoles',
                component:  defineAsyncComponent(() => import('../components/UserRoles/AddRoles.vue'))
            },

            {
                path: '/AddPermissions',
                name: 'AddPermissions',
                component: defineAsyncComponent(() => import('../components/UserPermissions/AddPermissions.vue'))
            },
            {
                path: '/ImportCategory',
                name: 'ImportCategory',
                component: defineAsyncComponent(() => import('../components/Imports/ImportCategory.vue'))
            },
            {
                path: '/ImportProduct',
                name: 'ImportProduct',
                component: defineAsyncComponent(() => import('../components/Imports/ImportProduct.vue'))
            },
            {
                path: '/ImportExportRecords',
                name: 'ImportExportRecords',
                component: defineAsyncComponent(() => import('../components/Imports/ImportExportRecords.vue'))
            },
            {
                path: '/ImportStock',
                name: 'ImportStock',
                component: defineAsyncComponent(() => import('../components/Imports/ImportStockIn.vue'))
            },
            {
                path: '/ImportAttendance',
                name: 'ImportAttendance',
                component: defineAsyncComponent(() => import('../components/Imports/ImportAttendance.vue'))
            },
           
           
            
            {
                path: '/TransactionLogs',
                name: 'TransactionLogs',
                component: defineAsyncComponent(() => import('../components/TransactionLogs/TransactionLogs.vue'))
            },
            {
                path: '/PushPullTransactionLog',
                name: 'PushPullTransactionLog',
                component:  defineAsyncComponent(() => import('../components/PushPullTransactionLog/PushPullTransactionLog.vue'))
            },
            
           
            {
                path: '/HolidaySetup',
                name: 'HolidaySetup',
                component: defineAsyncComponent(() => import('../components/Hr/ShiftManagement/HolidaySetup.vue'))
            },
            
            {
                path: '/Backup',
                name: 'Backup',
                component: defineAsyncComponent(() => import('../components/DatabaseBackup/Backup.vue'))
            },
            {
                path: '/Restore',
                name: 'Restore',
                component: defineAsyncComponent(() => import('../components/BranchUsers/BranchUsers.vue')),
            },
            
            {
                path: '/Roles',
                name: 'Roles',
                component: defineAsyncComponent(() => import('../components/UserRoles/Roles.vue'))
            },
            {
                path: '/signUp',
                name: 'SignUp',
                component:  defineAsyncComponent(() => import('../components/LoginCredentials/SignUp.vue'))
            },
            {
                path: '/AddLoginPermission',
                name: 'AddLoginPermission',
                component: defineAsyncComponent(() => import('../components/LoginPermission/AddLoginPermission.vue'))
            },

            {
                path: '/UserSetup',
                name: 'UserSetup',
                component: defineAsyncComponent(() => import('../components/SidebarMenu/UserSetup.vue')),
                children: [
                ],
            },

            {
                path: '/Permissions',
                name: 'Permissions',
                component: defineAsyncComponent(() => import('../components/UserPermissions/Permissions.vue'))
            },
            {
                path: '/NoblePermissions',
                name: 'NoblePermissions',
                component: defineAsyncComponent(() => import('../components/UserPermissions/NoblePermission.vue'))
            },
            {
                path: '/Bank',
                name: 'Bank',
                component:  defineAsyncComponent(() => import('../components/Bank/Bank.vue'))
            },
            {
                path: '/DenominationSetup',
                name: 'DenominationSetup',
                component: defineAsyncComponent(() => import('../components/DenominationSetup/DenominationSetup.vue')),
            },

            {
                path: '/FinancialYear',
                name: 'FinancialYear',
                component: defineAsyncComponent(() => import('../components/FinancialYear/FinancialYear.vue')),
            },
            {
                path: '/Deduction',
                name: 'Deduction',
                component: defineAsyncComponent(() => import('../components/Hr/Payroll/Deduction/Deduction.vue')),
            },
            {
                path: '/AttendanceDeductions',
                name: 'AttendanceDeductions',
                component: defineAsyncComponent(() => import('../components/Hr/Payroll/AttendanceDeductions.vue')),
            },
            {
                path: '/AttendanceCalculation',
                name: 'AttendanceCalculation',
                component: defineAsyncComponent(() => import('../components/Hr/Payroll/AttendanceCalculation.vue')),
            },
            {
                path: '/MonthlyDeduction',
                name: 'MonthlyDeduction',
                component: defineAsyncComponent(() => import('../components/Hr/Payroll/MonthlyDeduction/MonthlyDeduction.vue')),
            },
            {
                path: '/Shift',
                name: 'Shift',
                component: defineAsyncComponent(() => import('../components/Hr/Shift/Shift.vue')),
            },
            {
                path: '/Leave',
                name: 'Leave',
                component: defineAsyncComponent(() => import('../components/Hr/LeaveManagement/Leave.vue')),
            },
            {
                path: '/ShiftAssign',
                name: 'ShiftAssign',
                component: defineAsyncComponent(() => import('../components/Hr/ShiftAssign/ShiftAssign.vue')),
            },
            {
                path: '/AddShiftAssign',
                name: 'AddShiftAssign',
                component: defineAsyncComponent(() => import('../components/Hr/ShiftAssign/AddShiftAssign.vue')),
            },
            {
                path: '/AddFixedHours',
                name: 'AddFixedHours',
                component: defineAsyncComponent(() => import('../components/Shifts&WorkingHours/AddFixedHours.vue')),
            },
            {
                path: '/Contribution',
                name: 'Contribution',
                component: defineAsyncComponent(() => import('../components/Hr/Payroll/Contribution/Contribution.vue')),
            },
            {
                path: '/Allowance',
                name: 'Allowance',
                component: defineAsyncComponent(() => import('../components/Hr/Payroll/Allowance/Allowance.vue')),
            },
            {
                path: '/SalaryTemplate',
                name: 'SalaryTemplate',
                component: defineAsyncComponent(() => import('../components/Hr/Payroll/SalaryTemplate/SalaryTemplate.vue')),
            },
            {
                path: '/AddSalaryTemplate',
                name: 'AddSalaryTemplate',
                component: defineAsyncComponent(() => import('../components/Hr/Payroll/SalaryTemplate/AddSalaryTemplate.vue')),
            },
            {
                path: '/AllowanceType',
                name: 'AllowanceType',
                component: defineAsyncComponent(() => import('../components/Hr/Payroll/AllowanceType/AllowanceType.vue')),
            },
            {
                path: '/AddEmployeeSalary',
                name: 'AddEmployeeSalary',
                component: defineAsyncComponent(() => import('../components/Hr/Payroll/EmployeeSalary/AddEmployeeSalary.vue')),
            },
            {
                path: '/EmployeeSalary',
                name: 'EmployeeSalary',
                component: defineAsyncComponent(() => import('../components/Hr/Payroll/EmployeeSalary/EmployeeSalary.vue')),
            },
            {
                path: '/LoanPayment',
                name: 'LoanPayment',
                component: defineAsyncComponent(() => import('../components/Hr/Payroll/LoanPayment/LoanPayment.vue')),
            },
            {
                path: '/PayrollSchedule',
                name: 'PayrollSchedule',
                component: defineAsyncComponent(() => import('../components/Hr/Payroll/PayrollSchedule/PayrollSchedule.vue')),
            },
            {
                path: '/AddLoanPayment',
                name: 'AddLoanPayment',
                component: defineAsyncComponent(() => import('../components/Hr/Payroll/LoanPayment/AddLoanPayment.vue')),
            },
            {
                path: '/SalaryTaxSlab',
                name: 'SalaryTaxSlab',
                component: defineAsyncComponent(() => import('../components/Hr/Payroll/SalaryTaxSlab/SalaryTaxSlab.vue')),
            },
            {
                path: '/AddSalaryTaxSlab',
                name: 'AddSalaryTaxSlab',
                component: defineAsyncComponent(() => import('../components/Hr/Payroll/SalaryTaxSlab/AddSalaryTaxSlab.vue')),
            },
            {
                path: '/RunPayroll',
                name: 'RunPayroll',
                component: defineAsyncComponent(() => import('../components/Hr/Payroll/RunPayroll/RunPayroll.vue')),
            },
            {
                path: '/AddRunPayroll',
                name: 'AddRunPayroll',
                component: defineAsyncComponent(() => import('../components/Hr/Payroll/RunPayroll/AddRunPayroll.vue')),
            },
            {
                path: '/SyncData',
                name: 'SyncData',
                component: sync
            },
            {
                path: '/StartScreen',
                name: 'StartScreen',
                component: defineAsyncComponent(() => import('../components/UserScreen/StartScreen.vue')),
            },
            {
                path: '/NotPermission',
                name: 'NotPermission',
                component: defineAsyncComponent(() => import('../components/UserScreen/NotPermission.vue')),
            },
            {
                path: '/FlushDatabase',
                name: 'FlushDatabase',
                component: defineAsyncComponent(() => import('../components/FlushDatabase/FlushDatabase.vue')),
            },
            {
                path: '/GeographicalSetup',
                name: 'GeographicalSetup',
                component: defineAsyncComponent(() => import('../components/SidebarMenu/GeographicalSetup.vue')),
                children: [
                    {
                        path: '/City',
                        name: 'City',
                        component: defineAsyncComponent(() => import('../components/City/City.vue'))
                    },

                ]
            },
            
            {
                path: '/PushRecords',
                name: 'PushRecords',
                component: defineAsyncComponent(() => import('../components/PushRecords/PushRecords.vue')),
            },
            {
                path: '/PullRecords',
                name: 'PullRecords',
                component: defineAsyncComponent(() => import('../components/PullRecords/PullRecords.vue')),
            },

            {
                path: '/Upload',
                name: 'Upload',
                component: defineAsyncComponent(() => import('../components/Upload/Upload.vue')),
            },

            {
                path: '/Synchronization',
                name: 'Synchronization',
                component:  defineAsyncComponent(() => import('../components/SidebarMenu/Synchronization.vue')),
                children: [



                ],
            },

            {
                path: '/BackupAndRestore',
                name: 'BackupAndRestore',
                component: defineAsyncComponent(() => import('../components/SidebarMenu/Backup.vue')),
                children: [



                ],
            },
           

            

          
            {
                path: '/BankPosTerminal',
                name: 'BankPosTerminal',
                component: defineAsyncComponent(() => import('../components/BankPosTerminal/BankPosTerminal.vue')),
            },




            {
                path: '/ItemRules',
                name: 'ItemRules',
                component: defineAsyncComponent(() => import('../components/Rules/ItemRules.vue')),

            },
            {
                path: '/CompanyProcess',
                name: 'CompanyProcess',
                component:  defineAsyncComponent(() => import('../components/CompanyProcess/CompanyProcessList.vue'))
            },

            {
                path: '/ImportContact',
                name: 'ImportContact',
                component:  defineAsyncComponent(() => import('../components/Imports/ImportContact.vue'))

            },
            {
                path: '/ImportReparingOrder',
                name: 'ImportReparingOrder',
                component: defineAsyncComponent(() => import('../components/Imports/ImportReparingOrder.vue'))

            },
            {
                path: '/ApplicationUpdate',
                name: 'ApplicationUpdate',
                component:  defineAsyncComponent(() => import('../components/ApplicationUpdate/UpdateApplication.vue'))
            },


            {
                path: '/FinancialYear',
                name: 'FinancialYear',
                component: defineAsyncComponent(() => import('../components/FinancialYear/FinancialYear.vue')),
            },

            {
                path: '/CompanyOption',
                name: 'CompanyOption',
                component: defineAsyncComponent(() => import('../components/CompanyOptions/CompanyOption.vue')),
            },
            {
                path: '/Department',
                name: 'Department',
                component: defineAsyncComponent(() => import('../components/Hr/Employee/Department/Department.vue')),
            },
            {
                path: '/Designation',
                name: 'Designation',
                component: defineAsyncComponent(() => import('../components/Hr/Employee/Designation/Designation.vue')),
            },
            {
                path: '/aboutUs',
                name: 'aboutUs',
                component: defineAsyncComponent(() => import('../components/Company/AboutUs.vue'))
            },
            {
                path: '/ManualAttendance',
                name: 'ManualAttendance',
                component: defineAsyncComponent(() => import('../components/ManualAttendance/ManualAttendance.vue')),
            },
            {
                path: '/Attendance',
                name: 'Attendance',
                component: defineAsyncComponent(() => import('../components/ManualAttendance/Attendance.vue')),
            },
            {
                path: '/AttendanceReportNew',
                name: 'AttendanceReportNew',
                component: defineAsyncComponent(() => import('../components/ManualAttendance/AttendanceReportNew.vue')),
            },
            {
                path: '/AddHolidayOfMonth',
                name: 'AddHolidayOfMonth',
                component: defineAsyncComponent(() => import('../components/ManualAttendance/AddHolidayOfMonth.vue')),
            },
            {
                path: '/EmployeeHourOfAttendence',
                name: 'EmployeeHourOfAttendence',
                component: defineAsyncComponent(() => import('../components/ManualAttendance/EmployeeHourOfAttendence.vue')),
            },
            {
                path: '/AttendanceReport',
                name: 'AttendanceReport',
                component: defineAsyncComponent(() => import('../components/ManualAttendance/AttendanceReport.vue')),
            },
            {
                path: '/AttendanceReport2',
                name: 'AttendanceReport2',
                component: defineAsyncComponent(() => import('../components/ManualAttendance/AttendanceReport2.vue')),
            },
            {
                path: '/CommingSoon',
                name: 'CommingSoon',
                component: CommingSoon
            },
        ]
    }
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
//   stringifyQuery: (query) => stringifyQuery(query),
  routes
})

export default router

//function greet() {
//    $('.metismenu').metisMenu();
//}

router.beforeEach((to, from, next) => {
    
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', '/assets/js/app.js')
    document.head.appendChild(recaptchaScript);


    // if (to.query.fromDashboard === 'true') {
    //     localStorage.setItem('token', localStorage.getItem(to.query.token_name))


    // }
    localStorage.setItem('CurrentPath', to.name);

    if (to.name == "Login") {
        next();
    }
    else {
        const mainStore = useMainStore();
        if (mainStore.isAuthenticated) {
            next();
                      
        }
        else {
            next({ path: '/' });
        }
    }
    
});
